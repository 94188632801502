
import axios from "axios";
import { defineComponent, reactive, ref } from "vue";
import PostNode from "../components/PostNode.vue";
import store from "../store";

export default defineComponent({
  components: {
    PostNode,
  },
  setup() {
    let res = reactive({
      list: [],
      count: 0,
    });
    let pageRes = reactive({
      page: 1,
      size: 20,
    });
    let getList = (page: number, size: number) => {
      axios
        .get("forum/sage/list?page=" + page + "&size=" + size)
        .then((response) => {
          res.list = response.data.data.list;
          res.count = response.data.data.count;
        });
    };
    getList(0, 10);
    let userId = ref(store.getters.getUserId);
    return {
      userId,
      res,
      pageRes,
    };
  },
});
